<template>
  <v-container
    v-if="order"
    style="max-width: 800px"
    class="mx-auto"
    >
    <Detail
      :currentOrder="order"
      :checkout="false"
      />
  </v-container>
</template>

<script>
import { Order } from '@/graphql/queries/orders'

const Detail = () => import(/* webpackPrefetch: true */ "@/components/orders/Detail");

export default {
  data () {
    return {
      order: null
    }
  },

  components: {
    Detail
  },

  mounted () {
    this.fetchOrder()
  },

  methods: {
    fetchOrder () {
      this.$apollo.query({
        query: Order,
        variables: {
          orderToken: this.$route.params.orderToken
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.order = res.data.order
        localStorage.removeItem('order_token')
      })
    }
  }
}
</script>
