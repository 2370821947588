<template>
  <v-container
    style="max-width: 1000px"
    v-if="!loading"
    >
    <div
      v-if="order"
      >
      <template
        v-if="order.state == 'completed'"
        >
        <div
          class="text-h3 font-weight-bold secondary--text my-3 text-center"
          >
          ¡Felicitaciones!
        </div>

        <div
          class="text-center text-h6 font-weight-medium mb-6"
          >
          Su orden ha sido finalizada con éxito
        </div>

        <detail
          :currentOrder="order"
          :checkout="false"
          ></detail>
      </template>

      <template
        v-else
        >
        <failure
          :order="order"
          ></failure>
      </template>
    </div>

    <div
      v-else
      >
      <div
        class="text-center text-h3 font-weight-bold secondary--text"
        >
        ¡UPS!
      </div>

      <div
        class="text-center text-h6"
        >
        ¡Parece que no hay nada que ver aquí!
      </div>
    </div>
  </v-container>
</template>

<script>
import { Order } from '@/graphql/queries/orders'

const Detail = () => import(/* webpackPrefetch: true */ "@/components/orders/Detail");
const Failure = () => import(/* webpackPrefetch: true */ "@/components/orders/Failure");

export default {
  data: () => ({
    order: null,
    loading: false
  }),

  created () {
    this.fetchOrder ()
  },

  methods: {
    fetchOrder () {
      this.loading = true

      this.$apollo.query({
        query: Order,
        variables: {
          orderToken: localStorage.getItem('order_token')
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.loading = false
        this.order = res.data.order

        if (this.order.state == 'completed') {
          localStorage.removeItem('order_token')
        }
      })
    }
  },

  components: {
    Detail,
    Failure
  }
}
</script>
